import type { AppData } from '@modules/root/page/AppPage';
import { useRouteLoaderData } from '@remix-run/react';

export const useMaybeAppData = (): AppData | undefined => {
    return useRouteLoaderData<AppData>('routes/_app');
};

export const useAppData = (): AppData => {
    return useRouteLoaderData<AppData>('routes/_app') as AppData;
};
